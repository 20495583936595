import React from "react";
import { NUMBERS } from "../../../../constants/game";
import {
  ControlsContainer,
  EmptyNumberElem,
  NumberElem,
  SelectedNumberElem,
  NumbersWrapper,
} from "./styles";
import { useSudokuContext } from "../../context/SudokuContext";
import { useMediaQuery } from "@mui/material";
import {
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from "../../../../constants/breakpoints";

type NumbersPadProps = {
  onNumberClicked: (number: string) => void;
};

const NumbersPad = ({ onNumberClicked }: NumbersPadProps) => {
  const { numberSelected, everyNumberCount } = useSudokuContext();
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const isAllNumbersSet = (item: number) => {
    return everyNumberCount[item - 1] == 9;
  };
  const NumberLine = (numbers: Array<number>) => {
    return (
      <>
        {numbers.map((number) => {
          if (isAllNumbersSet(number)) {
            return <EmptyNumberElem key={number} />;
          }

          if (number.toString() === numberSelected) {
            return (
              <SelectedNumberElem key={number}>{number}</SelectedNumberElem>
            );
          } else {
            return (
              <NumberElem
                key={number}
                onClick={() => {
                  onNumberClicked(number.toString());
                }}
              >
                {number}
              </NumberElem>
            );
          }
        })}
      </>
    );
  };
  return (
    <ControlsContainer
      onKeyDown={(e) => {
        if (NUMBERS.includes(Number(e.key))) {
          onNumberClicked(e.key);
        }
      }}
      tabIndex={0}
    >
      <NumbersWrapper>{NumberLine(NUMBERS)}</NumbersWrapper>
    </ControlsContainer>
  );
};

export default NumbersPad;
