import { Button, styled } from "@mui/material";
import { tablet, phone_tablet, desktop, desktop_plus } from "../../constants/breakpoints";
import COLORS from "../../constants/colors";
import { MONTSERRAT } from "../../constants/fonts";

export const MainWrapper = styled("div")(() => ({
  marginTop: "12px",
  marginBottom: "16px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  ...tablet({
    gap: "20px",
  }),
  ...desktop({
    marginTop: "32px",
    marginBottom: "20px",
  }),
  ...desktop_plus({
    maxWidth: "400px",
  }),
}));

export const AdsBannerBox = styled("div")(() => ({
  // border: "1px solid gray",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ButtonWrapper = styled("div")(() => ({
  padding: "0 28px ",
  ...tablet({
    padding: 0,
  }),
}));
export const NewGameButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: 50,
  background: COLORS.BUTTON_LINEAR,
  borderRadius: 12,
  color: "white",
  fontSize: 17,
  fontWeight: 700,
  border: 0,
  outline: 0,
  fontFamily: MONTSERRAT,
  boxShadow: `0px ${theme.spacing(1.5)} ${theme.spacing(3)} ${COLORS.SHADOW}`,
  margin: "0 auto",
  display: "block",
  marginTop: theme.spacing(2.5),
  marginBottom: 30,
  textTransform: "uppercase",
  ...phone_tablet({ width: 322 }),
  ...tablet({
    marginTop: 0,
    width: 410,
  }),
}));
