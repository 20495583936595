import React from "react";

type Props = {
  isRotated?: boolean;
};

const SelectArrowIcon: React.FC<Props> = () => {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7.5 7L14 1"
        stroke="#2196F3"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SelectArrowIcon;
