import SudokuToolCollection from "sudokutoolcollection";
import replaceAll from "../utils/replaceAllHelper";

import { getChoices, checkDuplicationOfNumber } from "./sudokuSolver";

const sudokuTools = SudokuToolCollection();

const solveSudoku = (sudoku: string) => {
  const sudokuToSolve = replaceAll(sudoku, "0", ".").replaceAll(",", "");

  return sudokuTools.solver.solve(sudokuToSolve);
};

const calculateEveryNumberCount = (sudoku: string) => {
  const everyNumberCount = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  for (let i = 0; i < sudoku.length; i++) {
    if (sudoku[i] === "1") {
      everyNumberCount[0] += 1;
    } else if (sudoku[i] === "2") {
      everyNumberCount[1] += 1;
    } else if (sudoku[i] === "3") {
      everyNumberCount[2] += 1;
    } else if (sudoku[i] === "4") {
      everyNumberCount[3] += 1;
    } else if (sudoku[i] === "5") {
      everyNumberCount[4] += 1;
    } else if (sudoku[i] === "6") {
      everyNumberCount[5] += 1;
    } else if (sudoku[i] === "7") {
      everyNumberCount[6] += 1;
    } else if (sudoku[i] === "8") {
      everyNumberCount[7] += 1;
    } else if (sudoku[i] === "9") {
      everyNumberCount[8] += 1;
    }
  }

  return everyNumberCount;
};

const nextSelectedNumber = (
  numberSelected: number,
  everyNumberCount: number[]
): string => {
  if (numberSelected !== 0 && everyNumberCount[numberSelected - 1] < 9) {
    return numberSelected.toString();
  }

  // since numberSelected to 9
  let i = numberSelected - 1;
  while (i < 9) {
    if (everyNumberCount[i] < 9) {
      return (i + 1).toString();
    }

    i++;
  }

  // and from 1 to numberSelected
  i = 0;
  while (i < numberSelected) {
    if (everyNumberCount[i] < 9) {
      return (i + 1).toString();
    }
    i++;
  }

  return "0";
};

const getCandidates = (sudoku: string): number[][] => {
  const candidates: number[][] = [];

  for (let i = 0; i < 81; i++) {
    if (sudoku[i] == "0") {
      candidates.push(getChoices(sudoku, i));
    } else {
      candidates.push([]);
    }
  }

  return candidates;
};

const isSudokuSolved = (sudoku: string): boolean => {
  return (
    !sudoku.includes("0") &&
    sudoku.toString().replaceAll(",", "") === solveSudoku(sudoku.toString())
  );
};

const isErrorInCell = (sudoku: string, index: number): boolean => {
  if (sudoku[index] == "0") {
    return false;
  } else {
    return checkDuplicationOfNumber(sudoku, index);
  }
};

export {
  solveSudoku,
  calculateEveryNumberCount,
  nextSelectedNumber,
  getCandidates,
  isSudokuSolved,
  isErrorInCell,
};
