import React from "react";

type Props = {
  width?: number;
};

const DifficultyIcon: React.FC<Props> = ({ width = 23 }) => {
  return (
    <svg
      width={width}
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.03753 16.6137C8.52075 18.359 10.3196 19.3777 12.0649 18.8945C13.2424 18.5685 14.0952 17.6418 14.3723 16.5577L16.8348 5.64025L8.99213 13.3158C8.10735 14.1267 7.69695 15.3836 8.03753 16.6137Z"
        fill="#289AF4"
      />
      <path
        d="M19.6071 19.5009C18.9665 20.1498 17.9207 19.999 17.3704 19.2719C16.8202 18.5448 16.9796 17.5183 17.5812 16.833C18.3355 15.9737 18.9048 14.9605 19.2453 13.8561C19.7489 12.2224 19.7262 10.4718 19.1804 8.85176C18.8491 7.86828 18.3351 6.96313 17.6716 6.18085L18.6239 2.52105C20.3281 3.8887 21.6092 5.71855 22.3096 7.79754C23.0765 10.0738 23.1084 12.5335 22.4008 14.8288C21.8562 16.5957 20.8946 18.1964 19.6071 19.5009Z"
        fill="#289AF4"
      />
      <path
        d="M17.9547 2.02297C16.0378 0.701805 13.7621 -0.0048471 11.4306 2.50251e-05C9.02868 0.00504441 6.6891 0.764998 4.74271 2.17242C2.79631 3.57985 1.3416 5.56352 0.58427 7.84294C-0.173058 10.1224 -0.194676 12.5822 0.522473 14.8745C1.07451 16.6391 2.04278 18.2358 3.33573 19.5349C3.97896 20.1812 5.0242 20.026 5.57139 19.2966C6.11859 18.5672 5.95488 17.5413 5.35046 16.8586C4.59253 16.0024 4.01896 14.9916 3.6739 13.8887C3.16348 12.2571 3.17887 10.5064 3.71788 8.88407C4.25689 7.26175 5.29225 5.84991 6.67755 4.84821C8.06285 3.84651 9.72799 3.30563 11.4375 3.30206C12.9016 3.299 14.3347 3.69015 15.5888 4.4281L17.9547 2.02297Z"
        fill="#289AF4"
      />
    </svg>
  );
};

export default DifficultyIcon;
