import React from "react";
import { CellContent } from "./styles";

import GameCellHints from "../GameCellHints";
import { useSudokuContext } from "../../context/SudokuContext";

type GameCellProps = {
  index: number;
  value?: string | null;
  showHints: boolean;
  hints?: number[];
  numberSelected: string;
  isFixedCell: boolean;
  isErrorInCell: boolean;
  onPress: () => void;
} & typeof defaultProps;

const defaultProps = {
  index: 0,
  showHints: false,
  numberSelected: "0",
  isFixedCell: false,
  isErrorInCell: false,
};

const GameCell: React.FC<GameCellProps> = ({
  index,
  value,
  showHints,
  hints,
  numberSelected,
  isFixedCell,
  isErrorInCell,
  onPress,
}) => {
  const { isPause } = useSudokuContext();

  const cellBackground = (): string | undefined => {
    if (isPause) {
      return "white";
    } else if (isErrorInCell && !isFixedCell) {
      return "#FDD0DB";
    } else if (numberSelected === value) {
      return "#EBF6FF";
    }
  };

  const cellTextColor = () => {
    if (isPause) {
      return "transparent";
    } else if (isErrorInCell) {
      return "red";
    } else if (isFixedCell) {
      return "#289AF4";
    } else {
      return "#333333";
    }
  };

  const needShowHints = () => {
    if (showHints && !value && hints && hints.length) {
      return true;
    }

    return false;
  };

  const valueStr = (): string => {
    return value !== "0" && value != null ? value : "";
  };

  return (
    <CellContent
      color={cellTextColor()}
      background={cellBackground()}
      onClick={() => (!isPause ? onPress() : "")}
    >
      {needShowHints() ? (
        <GameCellHints index={index} hints={hints} />
      ) : (
        valueStr()
      )}
    </CellContent>
  );
};

export default GameCell;
