import { styled } from "@mui/material";
import { desktop, phone_tablet } from "../../../../constants/breakpoints";
import COLORS from "../../../../constants/colors";

type CellContentProps = {
  background?: string;
};

export const Cell = styled("div")<CellContentProps>(({ background }) => ({
  display: "grid",
  position: "absolute",
  width: "100%",
  height: "100%",
  gridTemplateRows: "1fr 1fr 1fr",
  background: background,
  fontSize: 18,
}));

export const Line = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
}));

export const Number = styled("div")(() => ({
  flex: 1,
  color: COLORS.GRAY_FOR_TEXT,
  fontWeight: 400,
  fontSize: "32%",
  textAlign: "center",
  ...phone_tablet({
    fontSize: "50%",
  }),
  ...desktop({
    fontSize: "57%",
  }),
}));
