import { styled } from "@mui/material";
import { relative } from "path";
import { createGlobalStyle } from "styled-components";
import { MONTSERRAT } from "../../constants/fonts";
import { tablet } from "../../constants/breakpoints";

export const GlobalStyle = createGlobalStyle({
  body: {
    fontFamily: MONTSERRAT,
  },
});

export const CoreWrapper = styled("div")(() => ({

  ...tablet({
    position: "relative",
    alignSelf: 'center',
    minHeight: "fit-content",
    // paddingBottom: 342,
  }),
}));

export const BottomContent = styled("div")(() => ({
  ...tablet({
    position: "absolute",
    width: "100%",
    bottom: 0,
  }),
}));
