import React from "react";
import { useMediaQuery } from "@mui/material";
import { MainWrapper, AdsBannerBox } from "./styles";
import AdsBanner from "../Ads";
import { DESKTOP_MEDIA_QUERY, TABLET_MEDIA_QUERY } from "../../constants/breakpoints";

type Props = {
  showPlayNowButton?: boolean;
};

const SideBanner: React.FC<Props> = () => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);

  const bannerWidth = () => {
    if (isDesktop) {
      return 400;
    }

    if (isTablet) {
      return 600;
    }

    return 340;
  };

  const bannerHeight = () => {
    if (isDesktop) {
      return 370;
    }

    if (isTablet) {
      return 400;
    }

    return 150;
  };

  return (
    <MainWrapper>
      <AdsBannerBox>
        <AdsBanner display="block" dataAdSlot="5794907371" width={bannerWidth()} height={bannerHeight()} />
      </AdsBannerBox>

      <AdsBannerBox>
        <AdsBanner display="block" dataAdSlot="6153486017" width={bannerWidth()} height={bannerHeight()} />
      </AdsBannerBox>
    </MainWrapper>
  );
};

export default SideBanner;
