import React from "react";

type Props = {
  color: string;
};

const HalfStarIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0V6.5L8.5 9V11L8.5 12.2229L3.24671 16L5.25329 9.88854L0 6.11146H6.49342L8.5 0Z"
        fill={color}
      />
    </svg>
  );
};

export default HalfStarIcon;
