export const ZERO_ROW = [0, 0, 0, 0, 0, 0, 0, 0, 0];
export const ZERO_TABLE = [
  ZERO_ROW,
  ZERO_ROW,
  ZERO_ROW,
  ZERO_ROW,
  ZERO_ROW,
  ZERO_ROW,
  ZERO_ROW,
  ZERO_ROW,
  ZERO_ROW,
];

export const NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9];
export const FIRST_NUMBERS = [1, 2, 3, 4, 5];
export const LAST_NUMBERS = [6, 7, 8, 9];
