import { styled } from "@mui/material";
import { tablet } from "../../../../constants/breakpoints";

type CellContentProps = {
  background?: string;
  color: string;
};

export const CellContent = styled("div")<CellContentProps>(
  ({ background, color }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: background,
    fontSize: 18,
    color: color,

    ...tablet({
      fontSize: 24,
    }),
  })
);
