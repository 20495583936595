import React from "react";

type Props = {
  width?: number;
};

const Expert: React.FC<Props> = ({ width = 21 }) => {
  return (
    <svg
      width={width}
      height="20"
      viewBox="0 -1 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74102 17.519C9.22424 19.2642 11.0231 20.283 12.7684 19.7998C13.9459 19.4737 14.7986 18.5471 15.0758 17.463L17.5383 6.54552L9.69562 14.221C8.81084 15.032 8.40044 16.2889 8.74102 17.519Z"
        fill="#289AF4"
      />
      <path
        d="M20.3105 20.4061C19.67 21.0551 18.6242 20.9043 18.0739 20.1772C17.5237 19.4501 17.6831 18.4235 18.2847 17.7383C19.039 16.879 19.6083 15.8658 19.9488 14.7614C20.4524 13.1277 20.4297 11.3771 19.8839 9.75703C19.5526 8.77355 19.0386 7.8684 18.3751 7.08612L19.3274 3.42633C21.0316 4.79397 22.3127 6.62382 23.0131 8.70281C23.78 10.979 23.8119 13.4387 23.1043 15.7341C22.5597 17.501 21.5981 19.1016 20.3105 20.4061Z"
        fill="#289AF4"
      />
      <path
        d="M18.6582 2.92824C16.7412 1.60708 14.4656 0.900426 12.1341 0.905298C9.73217 0.910318 7.39259 1.67027 5.4462 3.07769C3.4998 4.48512 2.04509 6.46879 1.28776 8.74821C0.530433 11.0276 0.508815 13.4874 1.22596 15.7798C1.778 17.5444 2.74627 19.141 4.03922 20.4401C4.68245 21.0864 5.72769 20.9313 6.27488 20.2019C6.82208 19.4725 6.65837 18.4466 6.05395 17.7639C5.29602 16.9077 4.72245 15.8969 4.37739 14.7939C3.86698 13.1624 3.88236 11.4117 4.42137 9.78934C4.96038 8.16702 5.99574 6.75519 7.38104 5.75349C8.76634 4.75178 10.4315 4.21091 12.141 4.20733C13.605 4.20427 15.0382 4.59543 16.2923 5.33337L18.6582 2.92824Z"
        fill="#289AF4"
      />
    </svg>
  );
};

export default Expert;
