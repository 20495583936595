import { useSudokuContext } from "../context/SudokuContext";
import { ImgWrapper, TimerWrapper, TimeWrapper, CustomImg } from "./styles";
import formatTime from "../helpers/formatTime";
import { useEffect, useState } from "react";
import { timerGame as Props } from "../../../types/timerGame";

const TimerSection: React.FC<Props> = ({ setTimeGame }) => {
  const { isPause, setIsPause, won, gameHistory, startGame, setStartGame } = useSudokuContext();
  const [currentTime, setCurrentTime] = useState(0);
  const tick = () => setCurrentTime(() => currentTime + 1);
  const onPauseClick = () => {
    setIsPause(!isPause);
  };

  useEffect(() => {
    const visibilitychange = () => {
      if (document.visibilityState !== "visible") {
        setIsPause(true);
      }
    };
    document.addEventListener("visibilitychange", () => visibilitychange());
    return () => {
      document.removeEventListener("visibilitychange", () => visibilitychange());
    };
  });

  useEffect(() => {
    if (won && setTimeGame) {
      setTimeGame(currentTime);
    }
  }, [won]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isPause && startGame && !won) {
      timeout = setTimeout(() => tick(), 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isPause, startGame, won, currentTime]);

  useEffect(() => {
    setCurrentTime(0);
  }, [won]);

  useEffect(() => {
    setStartGame(Boolean(gameHistory.length !== 0));
  }, [gameHistory]);

  return (
    <TimerWrapper>
      <ImgWrapper onClick={() => onPauseClick()}>
        {isPause ? (
          <CustomImg src="../../images/pause.svg" alt="pause image" width="44px" height="44px" />
        ) : (
          <CustomImg src="../../images/play-pause.svg" alt="pause image" width="44px" height="44px" />
        )}
      </ImgWrapper>
      <TimeWrapper>{formatTime(currentTime)}</TimeWrapper>
    </TimerWrapper>
  );
};
export default TimerSection;
