import { styled, Button } from "@mui/material";
import {
  desktop,
  tablet,
  desktop_plus,
  phone_tablet,
} from "../../../../constants/breakpoints";
import { MONTSERRAT, PROXIMA_NOVA } from "../../../../constants/fonts";
import COLORS from "../../../../constants/colors";

export const MainWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
}));
export const ContentWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: 100,
  backgroundImage: `url('/images/daily404.webp')`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundSize: "100% 100%",
  width: "90%",
  height: "calc(100vw*0.58)",
  margin: "32px 0 0 0",
  ...phone_tablet({
    paddingLeft: 0,
    display: "grid",
    gridColumnStart: "unset",
    gridTemplateColumns: "40% 60%",
  }),
  ...tablet({
    width: 688,
    height: 443,
    alignItems: "start",
    paddingLeft: 0,
    backgroundSize: "80% 80%",
  }),
  ...desktop({
    width: 688,
    height: 443,
  }),
  ...desktop_plus({
    width: 852,
    height: 572,
    margin: "121px 0",
  }),
}));

export const Title = styled("h1")(() => ({
  fontWeight: "700",
  color: COLORS.MAIN,
  fontSize: 36,
  width: "fit-content",
  textTransform: "capitalize",
  fontFamily: MONTSERRAT,
}));
export const Subtitle = styled("p")(() => ({
  fontWeight: "700",
  color: COLORS.MAIN,
  fontSize: 20,
  width: "fit-content",
  fontFamily: MONTSERRAT,
  marginTop: 25,
}));

export const PhoneWrapper = styled("div")(() => ({
  marginLeft: 30,
  marginTop: -20,
}));

export const ChooseChallenge = styled("p")(() => ({
  fontWeight: "700",
  color: COLORS.MAIN,
  fontSize: 14,
  marginTop: 44,
  ...tablet({
    fontSize: 24,
  }),
}));
export const DailyButton = styled(Button)(({ theme }) => ({
  width: 245,
  height: 50,
  background: COLORS.BUTTON_LINEAR_DAILY_CHALLENGE,
  borderRadius: 12,
  color: COLORS.WHITE,
  fontSize: 17,
  fontWeight: 700,
  border: 0,
  outline: 0,
  fontFamily: PROXIMA_NOVA,
  boxShadow: `0px ${theme.spacing(1.5)} ${theme.spacing(3)} ${COLORS.SHADOW}`,
  margin: "42px auto 0 auto",
  display: "flex",
  alignItems: "center",
  ...phone_tablet({
    width: 220,
  }),
  ...tablet({
    width: 300,
  }),
}));
export const ButtonhText = styled("div")(({ theme }) => ({
  fontFamily: PROXIMA_NOVA,
  color: COLORS.WHITE,
  fontWeight: "700",
  fonSize: 17,
  linHeight: 21,
}));
export const TabletWrapper = styled("div")(({ theme }) => ({
  fontFamily: PROXIMA_NOVA,
  color: COLORS.WHITE,
  fontWeight: "700",
  fonSize: 17,
  linHeight: 21,
  width: "100%",
  ...tablet({
    maxWidth: 485,
  }),
}));
export const TextSorry = styled("p")(({ theme }) => ({
  fontWeight: "700",
  color: COLORS.MAIN,
  fontSize: 56,
  width: "fit-content",
  textTransform: "capitalize",
  fontFamily: MONTSERRAT,
  ...tablet({ fontSize: 96 }),
}));
export const TextGame = styled("p")(({ theme }) => ({
  fontSize: 24,
  color: COLORS.MAIN,
  display: "flex",
  flexDirection: "row-reverse",
  marginTop: 30,
  ...tablet({
    fontSize: 40,
    whiteSpace: "nowrap",
  }),
}));
export const LinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  ...desktop_plus({
    marginTop: 105,
  }),
}));
