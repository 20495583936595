const solve = (board: number[]) => {
  const solvedBoard = board.slice(0);

  let backtrack = 0;
  let guesswork = 0;
  let dcount = 0;
  const startTime = Date.now();

  if (solve()) {
    stats(startTime);
    return solvedBoard;
  } else {
    stats(startTime);
    return 'no solution';
  }

  function solve(): boolean {
    const { index, moves, len } = analyze(solvedBoard);
    let movesVar = moves;

    if (index == null) return true;
    if (len > 1) guesswork++;
    for (let m = 1; movesVar; m <<= 1) {
      if (movesVar & m) {
        dcount++;
        solvedBoard[index] = m;
        if (solve()) return true;
        movesVar ^= m;
      }
    }
    solvedBoard[index] = 0;
    ++backtrack;
    return false;
  }

  function stats(startTime: number) {
    console.log(
      `${dcount} digits placed\n${backtrack} take-backs\n${guesswork} guesses\n${
        Date.now() - startTime
      } milliseconds`,
    );
  }
};

// const allowedDigits = (index: number) => {
//   console.log(
//     `Allowed digits: ${b2ds(analyze(this.readBoard()).allowed[index]).join(
//       ', ',
//     )}`,
//   );
// };

const analyze = (board: number[]) => {
  const allowed: number[] = board.map((x, i) => (x ? 0 : getMoves(board, i)));
  let bestIndex = 0;
  let bestLen = 100;

  for (let i = 0; i < 81; i++)
    if (!board[i]) {
      let moves = allowed[i];
      let len = 0;
      for (let m = 1; moves; m <<= 1)
        if (moves & m) {
          ++len;
          if (unique(allowed, i, m)) {
            allowed[i] = m;
            len = 1;
            break;
          }
          moves ^= m;
        }
      if (len < bestLen) {
        bestLen = len;
        bestIndex = i;
        if (!bestLen) break;
      }
    }
  return {
    index: bestIndex,
    moves: allowed[bestIndex],
    len: bestLen,
    allowed: allowed,
  };
};

const getMoves = (board: number[], index: number): number => {
  const { row, col } = i2rc(index);
  const r1 = 3 * ((row / 3) | 0);
  const c1 = 3 * ((col / 3) | 0);
  let moves = 0;
  for (let r = r1, i = 0; r < r1 + 3; r++) {
    for (let c = c1; c < c1 + 3; c++, i++) {
      moves |= board[rc2i(r, c)] | board[rc2i(row, i)] | board[rc2i(i, col)];
    }
  }
  return moves ^ 511;
};

const unique = (allowed: number[], index: number, value: number): boolean => {
  const { row, col } = i2rc(index);
  const r1 = 3 * ((row / 3) | 0);
  const c1 = 3 * ((col / 3) | 0);
  let ir = 9 * row;
  let ic = col;
  let uniq_row = true,
    uniq_col = true,
    uniq_3x3 = true;
  for (let r = r1; r < r1 + 3; ++r) {
    for (let c = c1; c < c1 + 3; ++c, ++ir, ic += 9) {
      if (uniq_3x3) {
        const i = rc2i(r, c);
        if (i != index && allowed[i] & value) uniq_3x3 = false;
      }
      if (uniq_row) {
        if (ir != index && allowed[ir] & value) uniq_row = false;
      }
      if (uniq_col) {
        if (ic != index && allowed[ic] & value) uniq_col = false;
      }
      if (!(uniq_3x3 || uniq_row || uniq_col)) return false;
    }
  }
  return uniq_row || uniq_col || uniq_3x3;
};

// Since it has 81 cells, we'll keep it as an array with 81 digits
// (zero for empty cells). It will be convenient to access it
// both using an index 0..80, or as (row, column), so we'll
// have a couple of functions to convert between the two:
// index -> { row, col }
const i2rc = (index: number): { row: number; col: number } => {
  return { row: (index / 9) | 0, col: index % 9 };
};
// { row, col } -> index
const rc2i = (row: number, col: number): number => {
  return row * 9 + col;
};

//  Next, we need a function that tells us what are the available
// choices, that is, what digits are acceptable in a given cell
// (I'm also gonna call them “moves”).
// Here's a reasonable implementation:
const getChoices = (board: string, index: number): number[] => {
  const choices: number[] = [];
  for (let value = 1; value <= 9; ++value) {
    if (acceptable(board, index, value.toString())) {
      choices.push(value);
    }
  }
  return choices;
};

// The actual rules of the puzzle are implemented in the function
// acceptable: we can use a digit if it's not already used on the
// same row, column or in the same 3x3 square. So here it is:
const acceptable = (board: string, index: number, value: string): boolean => {
  const { row, col } = i2rc(index);

  // if already present on the column, not acceptable
  for (let r = 0; r < 9; ++r) if (board[rc2i(r, col)] == value) return false;

  // if already present on the row, not acceptable
  for (let c = 0; c < 9; ++c) if (board[rc2i(row, c)] == value) return false;

  // if already present in the same 3x3 square, also not acceptable
  const r1 = Math.floor(row / 3) * 3;
  const c1 = Math.floor(col / 3) * 3;
  for (let r = r1; r < r1 + 3; ++r) {
    for (let c = c1; c < c1 + 3; ++c) {
      if (board[rc2i(r, c)] == value) return false;
    }
  }

  // we have a "go"
  return true;
};

const checkDuplicationOfNumber = (board: string, index: number): boolean => {
  const value: string = board[index];
  const { row, col } = i2rc(index);

  // if already present on the column, not valid value
  for (let r = 0; r < 9; ++r) {
    // except the same cell
    if (r == row) {
      continue;
    }

    if (board[rc2i(r, col)] == value) return true;
  }

  // if already present on the row, not valid value
  for (let c = 0; c < 9; ++c) {
    // except the same cell
    if (c == col) {
      continue;
    }

    if (board[rc2i(row, c)] == value) return true;
  }

  // if already present in the same 3x3 square, also not valid number
  const r1 = Math.floor(row / 3) * 3;
  const c1 = Math.floor(col / 3) * 3;
  for (let r = r1; r < r1 + 3; ++r) {
    for (let c = c1; c < c1 + 3; ++c) {
      // except the same cell
      if (c == col && r == row) {
        continue;
      }

      if (board[rc2i(r, c)] == value) return true;
    }
  }

  // no errors
  return false;
};

const b2ds = (byte: number): number[] => {
  const digits: number[] = [];
  for (let i = 1; byte; byte >>= 1, i++) if (byte & 1) digits.push(i);
  return digits;
};

export { solve, getChoices, checkDuplicationOfNumber };
