import { useRouter } from "next/router";
import { createContext, useContext, useMemo, useState } from "react";
import { LEVELS } from "@/constants/levels";

type SudokuContextProps = {
  gameHistory: string[];
  setGameHistory: React.Dispatch<React.SetStateAction<string[]>>;
  numberSelected: string;
  setNumberSelected: React.Dispatch<React.SetStateAction<string>>;
  gameArray: string;
  setGameArray: React.Dispatch<React.SetStateAction<string>>;
  difficulty: string;
  setDifficulty: React.Dispatch<React.SetStateAction<string>>;
  isEraseEnabled: boolean;
  setEraseEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isNotesEnabled: boolean;
  setNotesEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isHintEnabled: boolean;
  setHintEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  initArray: string;
  setInitArray: React.Dispatch<React.SetStateAction<string>>;
  everyNumberCount: number[];
  setEveryNumberCount: React.Dispatch<React.SetStateAction<number[]>>;
  gameCandidates: number[][];
  setGameCandidates: React.Dispatch<React.SetStateAction<number[][]>>;
  won: boolean;
  setWon: React.Dispatch<React.SetStateAction<boolean>>;
  isPause: boolean;
  setIsPause: React.Dispatch<React.SetStateAction<boolean>>;
  startGame: boolean;
  setStartGame: React.Dispatch<React.SetStateAction<boolean>>;
};

const SudokuContext = createContext<SudokuContextProps>({
  gameHistory: [],
  setGameHistory: () => undefined,
  numberSelected: "0",
  setNumberSelected: () => undefined,
  gameArray: "",
  setGameArray: () => undefined,
  difficulty: LEVELS.EASY,
  setDifficulty: () => undefined,
  isEraseEnabled: false,
  setEraseEnabled: () => undefined,
  isNotesEnabled: false,
  setNotesEnabled: () => undefined,
  isHintEnabled: false,
  setHintEnabled: () => undefined,
  initArray: "",
  setInitArray: () => undefined,
  everyNumberCount: [],
  setEveryNumberCount: () => undefined,
  gameCandidates: [],
  setGameCandidates: () => undefined,
  won: false,
  setWon: () => undefined,
  isPause: false,
  setIsPause: () => undefined,
  startGame: false,
  setStartGame: () => undefined,
});

type SudokuProviderProps = {
  children: React.ReactElement;
};

export const SudokuProvider = ({ children }: SudokuProviderProps) => {
  const [gameHistory, setGameHistory] = useState<string[]>([]);
  const [numberSelected, setNumberSelected] = useState<string>("0");
  const [gameArray, setGameArray] = useState<string>("");
  const [difficulty, setDifficulty] = useState<string>(LEVELS.EASY);

  const [isEraseEnabled, setEraseEnabled] = useState<boolean>(false);
  const [isNotesEnabled, setNotesEnabled] = useState<boolean>(false);
  const [isHintEnabled, setHintEnabled] = useState<boolean>(false);
  const [initArray, setInitArray] = useState<string>("");
  const [everyNumberCount, setEveryNumberCount] = useState<number[]>([]);
  const [gameCandidates, setGameCandidates] = useState<number[][]>([]);
  const [won, setWon] = useState<boolean>(false);
  const [isPause, setIsPause] = useState<boolean>(false);
  const [startGame, setStartGame] = useState<boolean>(false);

  const myPath = useRouter().asPath;
  const isEn = myPath.split("/")[1].length > 2;
  const pathLang = myPath.split("/")[1];
  const pathLevel = myPath.split("/")[2];
  let level = isEn ? pathLang : pathLevel;

  useMemo(() => {
    setDifficulty(level || LEVELS.EASY);
  }, [level]);

  return (
    <SudokuContext.Provider
      value={{
        gameHistory,
        setGameHistory,
        numberSelected,
        setNumberSelected,
        gameArray,
        setGameArray,
        difficulty,
        setDifficulty,
        isEraseEnabled,
        setEraseEnabled,
        isNotesEnabled,
        setNotesEnabled,
        isHintEnabled,
        setHintEnabled,
        initArray,
        setInitArray,
        everyNumberCount,
        setEveryNumberCount,
        gameCandidates,
        setGameCandidates,
        won,
        setWon,
        isPause,
        setIsPause,
        startGame,
        setStartGame,
      }}
    >
      {children}
    </SudokuContext.Provider>
  );
};

export const useSudokuContext = (): SudokuContextProps => useContext(SudokuContext);
