import React from "react";

import { useSudokuContext } from "../../context/SudokuContext";
import { Cell, Number, Line } from "./styles";

type GameCellHintsProps = {
  index: number;
  hints?: number[];
};

const GameCellHints: React.FC<GameCellHintsProps> = ({ index, hints }) => {
  const { numberSelected, gameCandidates, isNotesEnabled } = useSudokuContext();

  const isContainsNumber = (number: number) => {
    return hints != null && hints.includes(number);
  };

  const cellBackground = (): string | undefined => {
    return isNotesEnabled &&
      gameCandidates[index].includes(parseInt(numberSelected))
      ? "#307DF619"
      : undefined;
  };

  return (
    <Cell background={cellBackground()}>
      <Line>
        <Number>{isContainsNumber(1) ? 1 : " "}</Number>
        <Number>{isContainsNumber(2) ? 2 : " "}</Number>
        <Number>{isContainsNumber(3) ? 3 : " "}</Number>
      </Line>
      <Line>
        <Number>{isContainsNumber(4) ? 4 : " "}</Number>
        <Number>{isContainsNumber(5) ? 5 : " "}</Number>
        <Number>{isContainsNumber(6) ? 6 : " "}</Number>
      </Line>
      <Line>
        <Number>{isContainsNumber(7) ? 7 : " "}</Number>
        <Number>{isContainsNumber(8) ? 8 : " "}</Number>
        <Number>{isContainsNumber(9) ? 9 : " "}</Number>
      </Line>
    </Cell>
  );
};

export default GameCellHints;
