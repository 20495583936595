import React from "react";

type Props = {
  width?: number;
};

const Extreme: React.FC<Props> = ({ width = 21 }) => {
  return (
    <svg
      width={width}
      height="20"
      viewBox="0 -1 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.68618 17.2698C8.33916 16.0595 8.22884 13.9951 9.43918 12.6481C10.2558 11.7392 11.4653 11.3888 12.5594 11.6239L23.4302 14.2847L13.0639 17.885C11.9437 18.3156 10.6356 18.1229 9.68618 17.2698Z"
        fill="#289AF4"
      />
      <path
        d="M3.68942 20.4061C4.32994 21.0551 5.37582 20.9043 5.92606 20.1772C6.4763 19.4501 6.31688 18.4235 5.71532 17.7383C4.96097 16.879 4.39163 15.8658 4.05118 14.7614C3.54759 13.1277 3.5703 11.3771 4.11608 9.75703C4.66187 8.13697 5.70312 6.72948 7.09259 5.73358C8.48207 4.73767 10.1495 4.20376 11.859 4.20733C13.5685 4.21091 15.2336 4.75178 16.6189 5.75349C18.0042 6.75519 19.0396 8.16702 19.5786 9.78934C19.8251 10.5313 19.9621 11.3001 19.9894 12.072L23.2893 12.7662C23.3374 11.4112 23.1448 10.0503 22.7122 8.74821C21.9549 6.46879 20.5002 4.48512 18.5538 3.07769C16.6074 1.67027 14.2678 0.910318 11.8659 0.905298C9.46394 0.900279 7.12121 1.65045 5.16895 3.04972C3.21669 4.449 1.7537 6.42658 0.986848 8.70281C0.220001 10.979 0.188103 13.4387 0.895665 15.7341C1.44032 17.501 2.40191 19.1016 3.68942 20.4061Z"
        fill="#289AF4"
      />
      <path
        d="M22.7436 15.8754C22.1868 17.6021 21.2303 19.1645 19.9608 20.4401C19.3175 21.0864 18.2723 20.9313 17.7251 20.2019C17.1779 19.4725 17.3416 18.4466 17.946 17.7639C18.0729 17.6205 18.1946 17.4728 18.311 17.3211L22.7436 15.8754Z"
        fill="#289AF4"
      />
    </svg>
  );
};

export default Extreme;
