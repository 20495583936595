import { useTheme, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import COLORS from "../../../constants/colors";
import { useSudokuContext } from "../context/SudokuContext";
import { MOBILE_MEDIA_QUERY } from "../../../constants/breakpoints";

type Props = {
  active: boolean;
};

const UndoSvg: React.FC<Props> = ({ active }) => {
  const theme = useTheme();
  const { isPause } = useSudokuContext();

  return useMemo(
    () => (
      <svg
        style={{ width: "inherit", height: "inherit" }}
        width="inherit"
        height="inherit"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        cursor="pointer"
        filter={`drop-shadow(0px ${theme.spacing(0.5)} ${theme.spacing(2.5)} ${
          COLORS.SHADOW
        })`}
      >
        <path
          d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 -1.28293e-06 41.9117 0 27C1.28293e-06 12.0883 12.0883 -1.32465e-06 27 0C41.9117 1.32465e-06 54 12.0883 54 27Z"
          fill="white"
        />
        <path
          d="M19.26 24.015L20.95 22.8613C21.0843 22.7695 21.2356 22.7055 21.395 22.6728C21.5543 22.6401 21.7186 22.6394 21.8782 22.6708C22.0378 22.7022 22.1896 22.7651 22.3246 22.8557C22.4597 22.9464 22.5754 23.063 22.665 23.1988C22.8481 23.4738 22.9159 23.8098 22.8537 24.1343C22.7915 24.4588 22.6043 24.7459 22.3325 24.9338L18.2362 27.73C18.1019 27.8217 17.9506 27.8858 17.7913 27.9185C17.6319 27.9512 17.4677 27.9518 17.3081 27.9204C17.1485 27.889 16.9967 27.8262 16.8616 27.7355C16.7265 27.6449 16.6108 27.5283 16.5212 27.3925L13.7587 23.2475C13.5749 22.9725 13.5067 22.6361 13.5689 22.3112C13.6311 21.9863 13.8188 21.6989 14.0912 21.5113C14.2256 21.4195 14.3769 21.3555 14.5362 21.3228C14.6956 21.2901 14.8598 21.2894 15.0194 21.3208C15.179 21.3522 15.3308 21.4151 15.4659 21.5057C15.601 21.5964 15.7167 21.713 15.8062 21.8488L16.855 23.4225C18.375 18.2625 23.1 14.5 28.6937 14.5C35.5162 14.5 41.0462 20.0963 41.0462 27C41.0462 33.9038 35.5162 39.5 28.6937 39.5C28.5306 39.499 28.3692 39.4659 28.2188 39.4026C28.0685 39.3392 27.932 39.2469 27.8174 39.1308C27.7027 39.0147 27.612 38.8772 27.5505 38.7261C27.4889 38.5749 27.4578 38.4132 27.4587 38.25C27.4587 37.56 28.0125 37 28.6937 37C34.1512 37 38.5762 32.5225 38.5762 27C38.5762 21.4775 34.1512 17 28.6937 17C24.2637 17 20.515 19.95 19.26 24.015Z"
          fill={isPause ? "#289AF47C" : active ? "#289AF4" : "#289AF47C"}
        />
      </svg>
    ),
    [active, theme, isPause]
  );
};

const UndoIcon: React.FC<Props> = ({ active }) => {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const buttonSize = isMobile ? 44 : 54;

  return (
    <div style={{ height: buttonSize, width: buttonSize }}>
      <UndoSvg active={active} />
    </div>
  );
};

export default UndoIcon;
