const formatTime = (durationInSecs: number): string => {
  const hours = Math.floor(durationInSecs / 3600);
  const minutes = Math.floor(durationInSecs / 60);
  const seconds = Math.floor(durationInSecs % 60);
  let stringTimer = "";

  stringTimer += hours ? "" + hours + ":" : "";
  stringTimer += minutes ? (minutes < 10 ? "0" : "") + minutes + ":" : "00:";
  stringTimer += seconds < 10 ? "0" + seconds : seconds;

  return stringTimer;
};

export default formatTime;
