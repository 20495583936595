import React from "react";

type Props = {
  width?: number;
};

const Hard: React.FC<Props> = ({ width = 21 }) => {
  return (
    <svg
      width={width}
      height="20"
      viewBox="0 -1 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3796 19.4077C11.7037 20.6431 13.7697 20.5716 15.0052 19.2476C15.8387 18.3542 16.0815 17.1186 15.7512 16.0494L12.1453 5.45453L9.46999 16.0971C9.13945 17.2509 9.4464 18.537 10.3796 19.4077Z"
        fill="#289AF4"
      />
      <path
        d="M13.5474 4.3265C14.7533 4.53506 15.9029 5.01357 16.9074 5.7335C18.2969 6.7294 19.3381 8.13689 19.8839 9.75695C20.4297 11.377 20.4524 13.1276 19.9488 14.7613C19.6083 15.8657 19.039 16.8789 18.2847 17.7382C17.6831 18.4235 17.5237 19.45 18.0739 20.1771C18.6242 20.9042 19.67 21.055 20.3105 20.4061C21.5981 19.1016 22.5597 17.5009 23.1043 15.734C23.8119 13.4387 23.78 10.979 23.0131 8.70273C22.2463 6.4265 20.7833 4.44892 18.831 3.04964C16.9803 1.72312 14.7786 0.979959 12.5082 0.910545L13.5474 4.3265Z"
        fill="#289AF4"
      />
      <path
        d="M12.0216 0.906006C9.65919 0.934134 7.36219 1.69217 5.4462 3.07762C3.4998 4.48504 2.04509 6.46871 1.28776 8.74813C0.530433 11.0275 0.508815 13.4874 1.22596 15.7797C1.778 17.5443 2.74627 19.141 4.03922 20.4401C4.68245 21.0864 5.72769 20.9312 6.27488 20.2018C6.82208 19.4724 6.65837 18.4465 6.05395 17.7638C5.29602 16.9076 4.72245 15.8968 4.37739 14.7938C3.86698 13.1623 3.88236 11.4116 4.42137 9.78926C4.96038 8.16694 5.99574 6.75511 7.38104 5.75341C8.52322 4.9275 9.85564 4.41487 11.2463 4.25837L12.0216 0.906006Z"
        fill="#289AF4"
      />
    </svg>
  );
};

export default Hard;
