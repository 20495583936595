import React from "react";
import MainContent from "./MainContent";
import { CoreWrapper } from "./styles";
import { SudokuProvider } from "./context/SudokuContext";
import { GameProps as Props } from "../../types/popup";

const Game: React.FC<Props> = ({ dailyChallengeDate, sudokuDailyChallenge }) => {
  return (
    <SudokuProvider>
      <CoreWrapper>
        <MainContent dailyChallengeDate={dailyChallengeDate} sudokuDailyChallenge={sudokuDailyChallenge} />
      </CoreWrapper>
    </SudokuProvider>
  );
};

export default Game;
