import styles from "./GameWinModal.module.scss";
import { GameWinModalProps } from "./types";
import { DailyChallegen, GameWinSection, MetricsSection, NewGameBtn } from "./GameWinModal.components";
import { BaseModal, MonthlyAwardModal } from "@/components/modal";
import { LEVELS } from "@/constants/levels";

const GameWinModal: React.FC<GameWinModalProps> = ({
  isModalOpen,
  onGameWinModalClosed,
  timeGame,
  progress,
  month,
  levelsLeft,
  hints,
  notes,
  difficulty,
  isDailySudokuGame,

  isOpenMonthlyAward,
}) => {
  return (() => {
    if (progress === 100) {
      return <MonthlyAwardModal onClose={onGameWinModalClosed} isOpen={isOpenMonthlyAward} monthNumber={month} />;
    } else {
      return (
        <BaseModal isOpen={isModalOpen} onClose={onGameWinModalClosed} modalWrapperStyles={styles.modalWrapper}>
          {(() => {
            if (isDailySudokuGame) {
              return <DailyChallegen progress={progress} levelsLeft={levelsLeft} month={month} />;
            } else {
              return <GameWinSection />;
            }
          })()}

          <MetricsSection
            hints={hints}
            notes={notes}
            timeGame={timeGame}
            difficulty={isDailySudokuGame ? LEVELS.EASY.toLowerCase() : difficulty}
          />
          <NewGameBtn isDailySudokuGame={isDailySudokuGame} onClick={onGameWinModalClosed} />
        </BaseModal>
      );
    }
  })();
};

export default GameWinModal;
