import { BestTime } from "../../../api/models/userAnnualAward";
import { daysInMonth } from "../../../utils/dateHelper";

const calculateLevelsLeft = (year: string, month: string, completedGames: BestTime[]): number => {
  const daysInMonthVal = daysInMonth(year, month);

  return daysInMonthVal - completedGames.length;
};

export default calculateLevelsLeft;
