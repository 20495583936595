/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import useTranslation, {
  useRoutes,
} from "../../../../helpers/translationTexts/useTranslation";
import { PHONE_TABLET_QUERY } from "../../../../constants/breakpoints";

import {
  MainWrapper,
  ContentWrapper,
  Title,
  Subtitle,
  PhoneWrapper,
  ChooseChallenge,
  DailyButton,
  ButtonhText,
  TabletWrapper,
  TextSorry,
  TextGame,
  LinkContainer,
} from "./styles";
import { useMediaQuery } from "@mui/material";
import Router from "next/router";

const NoGame: React.FC = () => {
  const routerDailySudoku = useRoutes().DailySudoku;
  const isPhone = !useMediaQuery(PHONE_TABLET_QUERY);
  const contentPhone = () => {
    return (
      <PhoneWrapper>
        <Title>{useTranslation("sorry")},</Title>
        <Subtitle>{useTranslation("gameNotFount")}</Subtitle>
      </PhoneWrapper>
    );
  };

  const dailyButton = () => {
    return (
      <DailyButton onClick={() => Router.push(routerDailySudoku)}>
        <ButtonhText>{useTranslation("dailyChallenges")}</ButtonhText>
      </DailyButton>
    );
  };
  const contentTablet = () => {
    return (
      <TabletWrapper>
        <TextSorry>{useTranslation("sorry")},</TextSorry>
        <TextGame>{useTranslation("gameNotFount")}</TextGame>
        <LinkContainer>
          <ChooseChallenge>{useTranslation("chooseChallenge")}</ChooseChallenge>
          {dailyButton()}
        </LinkContainer>
      </TabletWrapper>
    );
  };
  return (
    <MainWrapper>
      <ContentWrapper>
        {!isPhone && <div></div>}
        {isPhone ? contentPhone() : contentTablet()}
      </ContentWrapper>
      {isPhone && (
        <ChooseChallenge>{useTranslation("chooseChallenge")}</ChooseChallenge>
      )}
      {isPhone && dailyButton()}
    </MainWrapper>
  );
};

export default NoGame;
