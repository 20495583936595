import useTranslation from "@/helpers/translationTexts/useTranslation";
import AppDownloadButton from "@/components/AppDownloadButton";
import { InstallAppEvents } from "@/constants/analyticEvents";
import styles from "./GameWinModal.module.scss";
import { DailyChallegenProps, MetricsSectionProps, NewGameBtnProps } from "./types";
import Image from "next/image";
import formatTime from "../helpers/formatTime";
import pointsManager from "@/api/PointsManager";

export const GameWinSection = () => {
  const youWinTitleText = useTranslation("youWin");
  const youWinText = useTranslation("youWinText");

  const downloadAppAction = InstallAppEvents.installAppHomeGameOverPopup;

  return (
    <div className={styles.textWrapper}>
      <h2 className={styles.modalTitle}>{youWinTitleText}</h2>
      <p className={styles.modalDesc}>{youWinText}</p>
      <div className={styles.appWrapper}>
        <AppDownloadButton market="google" action={downloadAppAction} />
        <AppDownloadButton market="apple" action={downloadAppAction} />
      </div>
    </div>
  );
};

export const DailyChallegen: React.FC<DailyChallegenProps> = ({ levelsLeft, month, progress }) => {
  const newLevelsLeftMessage = `${useTranslation(`levelsLeftMessage`).replace("%s", "")}${levelsLeft}`;
  const dailyChallengesText = useTranslation("dailyChallenges").replace("s", "");

  return (
    <div className={styles.textWrapper}>
      <h2 className={styles.modalTitle}>{dailyChallengesText}</h2>
      <p className={styles.modalDesc}>{newLevelsLeftMessage}</p>

      <Image src={`/images/cup/shade/${month}.svg`} alt="cup of month" width={110} height={170} />
      <div className={styles.awardsProgressThumb}>
        <div className={styles.awardsProgress} style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export const MetricsSection: React.FC<MetricsSectionProps> = ({ timeGame, hints, notes, difficulty }) => {
  const winLevelText = useTranslation("winLevel");
  const timeText = useTranslation("time");
  const hintsText = useTranslation("hints");
  const notesText = useTranslation("notes");
  const finalPointsText = useTranslation("finalPoints");
  const levelText = useTranslation(difficulty.toUpperCase());
  const pointsText = useTranslation("points");
  const yesText = useTranslation("yes");
  const noText = useTranslation("no");

  const timeGameText = formatTime(timeGame);
  const hintsModalMessage = hints <= 0 ? `${hints} (-${hints * 10}% ${pointsText})` : `${hints} (-100% ${pointsText})`;
  const notesModalMessage = notes ? `${yesText} (-50% ${pointsText})` : `${noText}`;

  const finalPoints = pointsManager.calculatePoints(difficulty, notes, hints);

  return (
    <ul className={styles.metricsWrapper}>
      <li className={styles.metricsItem}>
        <div className={styles.metricsItemTextWrapper}>
          <Image className={styles.metricsItemImg} width={22} height={22} src="/images/level.svg" alt="level icon" />
          <p className={styles.metricsItemText}>{winLevelText}</p>
        </div>
        <p className={styles.metricsItemText}>{levelText}</p>
      </li>

      <li className={styles.metricsItem}>
        <div className={styles.metricsItemTextWrapper}>
          <Image className={styles.metricsItemImg} width={22} height={22} src="/images/time.svg" alt="time icon" />
          <p className={styles.metricsItemText}>{timeText}</p>
        </div>
        <p className={styles.metricsItemText}>{timeGameText}</p>
      </li>

      <li className={styles.metricsItem}>
        <div className={styles.metricsItemTextWrapper}>
          <Image className={styles.metricsItemImg} width={22} height={22} src="/images/hints.svg" alt="hints icon" />
          <p className={styles.metricsItemText}>{hintsText}</p>
        </div>
        <p className={styles.metricsItemText}>{hintsModalMessage}</p>
      </li>

      <li className={styles.metricsItem}>
        <div className={styles.metricsItemTextWrapper}>
          <Image className={styles.metricsItemImg} width={22} height={22} src="/images/notes.svg" alt="hints icon" />
          <p className={styles.metricsItemText}>{notesText}</p>
        </div>
        <p className={styles.metricsItemText}>{notesModalMessage}</p>
      </li>

      <li className={styles.metricsItem}>
        <div className={styles.metricsItemTextWrapper}>
          <Image
            className={styles.metricsItemImg}
            width={22}
            height={22}
            src="/images/best-result.svg"
            alt="hints icon"
          />
          <p className={styles.metricsItemText}>{finalPointsText}</p>
        </div>
        <p className={styles.metricsItemText}>{finalPoints}</p>
      </li>
    </ul>
  );
};

export const NewGameBtn: React.FC<NewGameBtnProps> = ({ isDailySudokuGame, onClick }) => {
  const newGameText = useTranslation(isDailySudokuGame ? "ok" : "startNewGame");
  return (
    <button type="button" onClick={onClick} className={styles.newGameBtn}>
      {newGameText}
    </button>
  );
};
