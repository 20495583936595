import React from "react";

type Props = {
  color: string;
};

const StarIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0L10.5066 6.11146H17L11.7467 9.88854L13.7533 16L8.5 12.2229L3.24671 16L5.25329 9.88854L0 6.11146H6.49342L8.5 0Z"
        fill={color}
      />
    </svg>
  );
};

export default StarIcon;
