import { useTheme, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import COLORS from "../../../constants/colors";
import { useSudokuContext } from "../context/SudokuContext";
import { MOBILE_MEDIA_QUERY } from "../../../constants/breakpoints";

type Props = {
  active: boolean;
};

const HintsSvg: React.FC<Props> = ({ active }) => {
  const theme = useTheme();
  const { isPause } = useSudokuContext();

  return useMemo(
    () => (
      <svg
        style={{ width: "inherit", height: "inherit" }}
        width="inherit"
        height="inherit"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        cursor="pointer"
        filter={`drop-shadow(0px ${theme.spacing(0.5)} ${theme.spacing(2.5)} ${
          COLORS.SHADOW
        })`}
      >
        <path
          d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 -1.28293e-06 41.9117 0 27C1.28293e-06 12.0883 12.0883 -1.32465e-06 27 0C41.9117 1.32465e-06 54 12.0883 54 27Z"
          fill={active ? COLORS.MAIN : COLORS.WHITE}
        />
        <path
          d="M23.25 39.5H30.75V37H23.25V39.5Z"
          fill={isPause ? "#289AF47C" : active ? COLORS.WHITE : COLORS.MAIN}
        />
        <path
          d="M23.25 35.75H30.75L31.0325 33.25C31.1845 32.4217 31.4333 31.614 31.7737 30.8438C32.2075 30.225 32.6675 29.685 33.1125 29.1638L33.1337 29.1388C34.7571 27.6039 35.6991 25.4835 35.75 23.25C35.75 18.4175 31.8325 14.5 27 14.5C22.1675 14.5 18.25 18.4175 18.25 23.25C18.2933 25.4972 19.2426 27.6316 20.8825 29.1688C21.3287 29.69 21.79 30.2238 22.2325 30.8488C22.5733 31.617 22.8213 32.4231 22.9712 33.25L23.25 35.75Z"
          fill={isPause ? "#289AF47C" : active ? COLORS.WHITE : COLORS.MAIN}
        />
        <path
          d="M27.625 25.5828C27.9986 25.3666 28.25 24.9627 28.25 24.5C28.25 23.8096 27.6904 23.25 27 23.25C26.3096 23.25 25.75 23.8096 25.75 24.5C25.75 24.9627 26.0014 25.3666 26.375 25.5828V35.75H27.625V25.5828Z"
          fill={active ? COLORS.MAIN : COLORS.WHITE}
        />
      </svg>
    ),
    [active, theme, isPause]
  );
};

const HintsIcon: React.FC<Props> = ({ active }) => {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const buttonSize = isMobile ? 44 : 54;

  return (
    <div style={{ height: buttonSize, width: buttonSize }}>
      <HintsSvg active={active} />
    </div>
  );
};

export default HintsIcon;
