import { Button, styled } from "@mui/material";
import COLORS from "../../../../constants/colors";
import { MONTSERRAT } from "../../../../constants/fonts";
import { desktop, tablet } from "../../../../constants/breakpoints";

export const ControlsContainer = styled("div")(() => ({
  ...tablet({
    display: "flex",
    flexDirection: "column",
    width: 245,
    justifyContent: "space-between",
  }),
  ...desktop({
    width: 300,
  }),
}));

export const ControlsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(1.5),
  ...tablet({
    marginTop: 0,
    justifyContent: "space-between",
    gap: 0,
  }),
}));

export const ControlOption = styled("div")(() => ({
  position: "relative",
  "&:hover": {
    outline: "none",
  },
}));

export const ControlTitle = styled("p")(() => ({
  textAlign: "center",
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  color: COLORS.MAIN,
  fontWeight: 500,
  fontSize: 10,
  marginTop: 8,
  cursor: "pointer",
  userSelect: "none",
  "&:hover": {
    outline: "none",
  },
}));

export const ModeBox = styled("div")(({ className }) => ({
  position: "absolute",
  width: 22,
  height: 22,
  fontSize: 8,
  color: COLORS.MAIN,
  borderRadius: 11,
  top: 0,
  right: -6,
  background: COLORS.WHITE,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: `1px solid ${
    className?.includes("active") ? COLORS.MAIN : COLORS.WHITE
  }`,
  cursor: "pointer",
  userSelect: "none",
  "&:hover": {
    outline: "none",
  },
}));

export const NewGameButton = styled(Button)(({ theme }) => ({
  width: 245,
  height: 50,
  background: COLORS.BUTTON_LINEAR,
  borderRadius: 12,
  color: "white",
  fontSize: 17,
  fontWeight: 700,
  border: 0,
  outline: 0,
  fontFamily: MONTSERRAT,
  boxShadow: `0px ${theme.spacing(1.5)} ${theme.spacing(3)} ${COLORS.SHADOW}`,
  margin: "0 auto",
  display: "block",
  marginTop: theme.spacing(2.5),
  ...tablet({
    marginTop: 0,
    width: "100%",
  }),
}));

export const PlaceReserve = styled("div")(({ theme }) => ({
  width: 245,
  height: 50,
  margin: "0 auto",
  display: "block",
  marginTop: theme.spacing(2.5),
  ...tablet({
    marginTop: 0,
    width: "100%",
  }),
}));
