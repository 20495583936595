import React, { useEffect, useRef } from "react";
import { useSudokuContext } from "../../context/SudokuContext";
import GameCell from "../GameCell";
import { ImageWrapper, StyledBoard, StyledCell, StyledImage } from "./styles";

import { isErrorInCell } from "../../sudokuEngine";
import { Grid } from "@mui/material";
import { NUMBERS } from "../../../../constants/game";

type BoardProps = {
  onCellClicked: (indexOfArray: number) => void;
  onNumberClicked: (number: string) => void;
};

const Board = ({ onCellClicked, onNumberClicked }: BoardProps) => {
  const stack: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const {
    numberSelected,
    gameArray,
    initArray,
    isNotesEnabled,
    gameCandidates,
    isPause,
    setIsPause,
  } = useSudokuContext();

  const isCellWithError = (indexInArray: number) => {
    return isErrorInCell(gameArray, indexInArray);
  };

  const gameFocusBoardRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (gameFocusBoardRef.current) {
      gameFocusBoardRef.current.focus();
    }
  }, []);

  return (
    <>
      <StyledBoard
        ref={gameFocusBoardRef}
        container
        onKeyDown={(e) => {
          if (NUMBERS.includes(Number(e.key))) {
            onNumberClicked(e.key);
          }
        }}
        tabIndex={0}
      >
        {isPause && (
          <ImageWrapper>
            <StyledImage
              onClick={() => setIsPause(false)}
              src="/images/continue.svg"
              alt="continue"
            />
          </ImageWrapper>
        )}
        {stack.map((row, rowIndex) => (
          <Grid item xs={12} container key={rowIndex}>
            {stack.map((column, columnIndex) => {
              const indexOfArray = row * 9 + column;
              const value = gameArray[indexOfArray];

              const filledCell = value !== "0";

              return (
                <StyledCell
                  key={columnIndex}
                  className={`
  								${rowIndex % 3 === 0 ? "top" : ""}
  								${columnIndex % 3 === 2 ? "right" : ""}
  								${rowIndex % 3 === 2 ? "bottom" : ""}
  								${columnIndex % 3 === 0 ? "left" : ""}
  							`}
                >
                  <GameCell
                    key={indexOfArray + "cell"}
                    index={indexOfArray}
                    value={filledCell ? value : null}
                    numberSelected={numberSelected}
                    showHints={isNotesEnabled}
                    hints={gameCandidates[indexOfArray]}
                    isFixedCell={initArray[indexOfArray] !== "0"}
                    isErrorInCell={isCellWithError(indexOfArray)}
                    onPress={() => onCellClicked(indexOfArray)}
                  />
                </StyledCell>
              );
            })}
          </Grid>
        ))}
      </StyledBoard>
    </>
  );
};

export default Board;
