import { styled } from "@mui/material";
import COLORS from "../../../../constants/colors";
import { desktop, tablet } from "../../../../constants/breakpoints";

export const ControlsContainer = styled("div")(() => ({
  outline: "none !important",
  ...tablet({
    display: "flex",
    flexDirection: "column",
    width: 245,
    justifyContent: "space-between",
  }),
  ...desktop({
    width: 300,
  }),
}));

export const NumbersWrapper = styled("div")(({ theme }) => ({
  userSelect: "none",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  marginTop: theme.spacing(3.5),
  ...tablet({
    marginTop: 0,
    justifyContent: "space-between",
  }),
}));
export const EmptyNumberElem = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 42,
  width: "calc(100vw / 10.5)",
  margin: theme.spacing(0, 0.2, 1),

  ...tablet({
    width: 68,
    height: 68,
    margin: 0,
    marginBottom: theme.spacing(2),
  }),
  ...desktop({
    width: 90,
    height: 90,
  }),
}));

export const NumberElem = styled("div")(({ theme }) => ({
  fontSize: 14,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.MAIN,
  background: COLORS.SECONDARY,
  height: 42,
  width: "calc(100vw / 10.5)",
  borderRadius: 10,
  margin: theme.spacing(0, 0.2, 1),
  cursor: "pointer",

  ...tablet({
    width: 68,
    height: 68,
    borderRadius: 15,
    fontSize: 34,
    margin: 0,
    marginBottom: theme.spacing(2),
  }),
  ...desktop({
    fontSize: 34,
    width: 90,
    height: 90,
  }),
}));

export const SelectedNumberElem = styled("div")(({ theme }) => ({
  fontSize: 14,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: COLORS.MAIN,
  background: COLORS.NUMBER_SELECTED,
  height: 42,
  width: "calc(100vw / 10.5)",
  borderRadius: 10,
  margin: theme.spacing(0, 0.2, 1),
  cursor: "pointer",

  ...tablet({
    width: 68,
    height: 68,
    borderRadius: 15,
    fontSize: 34,
    margin: 0,
    marginBottom: theme.spacing(2),
  }),
  ...desktop({
    fontSize: 34,
    width: 90,
    height: 90,
  }),
}));
