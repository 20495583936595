import { useTheme, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import COLORS from "../../../constants/colors";
import { useSudokuContext } from "../context/SudokuContext";
import { MOBILE_MEDIA_QUERY } from "../../../constants/breakpoints";

type Props = {
  active: boolean;
};

const EraseSvg: React.FC<Props> = ({ active }) => {
  const theme = useTheme();
  const { isPause } = useSudokuContext();

  return useMemo(
    () => (
      <svg
        style={{ width: "inherit", height: "inherit" }}
        width="inherit"
        height="inherit"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        cursor="pointer"
        filter={`drop-shadow(0px ${theme.spacing(0.5)} ${theme.spacing(2.5)} ${
          COLORS.SHADOW
        })`}
      >
        <path
          d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 -1.28293e-06 41.9117 0 27C1.28293e-06 12.0883 12.0883 -1.32465e-06 27 0C41.9117 1.32465e-06 54 12.0883 54 27Z"
          fill={active ? COLORS.MAIN : COLORS.WHITE}
        />
        <path
          d="M32.3 16.45L38.4875 22.625C39.4625 23.6125 39.4625 25.1875 38.4875 26.175L27 37.6625C26.0609 38.5989 24.7887 39.1248 23.4625 39.1248C22.1363 39.1248 20.8641 38.5989 19.925 37.6625L15.5125 33.25C14.5375 32.2625 14.5375 30.6875 15.5125 29.7L28.7625 16.45C29.75 15.475 31.325 15.475 32.3 16.45ZM17.275 31.475L21.7 35.8875C22.675 36.875 24.25 36.875 25.2375 35.8875L29.65 31.475L23.4625 25.2875L17.275 31.475Z"
          fill={isPause ? "#289AF47C" : active ? COLORS.WHITE : COLORS.MAIN}
        />
      </svg>
    ),
    [active, theme, isPause]
  );
};

const EraseIcon: React.FC<Props> = ({ active }) => {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const buttonSize = isMobile ? 44 : 54;

  return (
    <div style={{ height: buttonSize, width: buttonSize }}>
      <EraseSvg active={active} />
    </div>
  );
};

export default EraseIcon;
