import { styled } from "@mui/material";
import { desktop_plus, tablet } from "../../../constants/breakpoints";

export const StyledContainer = styled("div")(({ className }) => ({
  height: className?.includes("fullHeight") ? "100%" : "auto",
  margin: "0 auto",
  ...tablet({
    padding: 0,
    width: 688,
  }),
  ...desktop_plus({
    padding: 0,
    width: 1290,
  }),
}));
