import { useTheme, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import COLORS from "../../../constants/colors";
import { useSudokuContext } from "../context/SudokuContext";
import { MOBILE_MEDIA_QUERY } from "../../../constants/breakpoints";

type Props = {
  active: boolean;
};

const NoteSvg: React.FC<Props> = ({ active }) => {
  const theme = useTheme();
  const { isPause } = useSudokuContext();

  return useMemo(
    () => (
      <svg
        style={{ width: "inherit", height: "inherit" }}
        width="inherit"
        height="inherit"
        viewBox="0 0 54 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        cursor="pointer"
        filter={`drop-shadow(0px ${theme.spacing(0.5)} ${theme.spacing(2.5)} ${
          COLORS.SHADOW
        })`}
      >
        <path
          d="M54 27C54 41.9117 41.9117 54 27 54C12.0883 54 -1.28293e-06 41.9117 0 27C1.28293e-06 12.0883 12.0883 -1.32465e-06 27 0C41.9117 1.32465e-06 54 12.0883 54 27Z"
          fill={active ? COLORS.MAIN : COLORS.WHITE}
        />

        <path
          d="M22.8837 36.6338L34.5 25.0175L28.9825 19.5L17.3662 31.1163C17.2063 31.2764 17.0927 31.4768 17.0375 31.6963L15.75 38.25L22.3025 36.9625C22.5225 36.9075 22.7237 36.7938 22.8837 36.6338ZM38.25 21.2675C38.7187 20.7987 38.982 20.1629 38.982 19.5C38.982 18.8371 38.7187 18.2014 38.25 17.7325L36.2675 15.75C35.7987 15.2814 35.1629 15.0181 34.5 15.0181C33.8371 15.0181 33.2013 15.2814 32.7325 15.75L30.75 17.7325L36.2675 23.25L38.25 21.2675Z"
          fill={isPause ? "#289AF47C" : active ? COLORS.WHITE : COLORS.MAIN}
        />
      </svg>
    ),
    [active, theme, isPause]
  );
};

const NoteIcon: React.FC<Props> = ({ active }) => {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const buttonSize = isMobile ? 44 : 54;

  return (
    <div style={{ height: buttonSize, width: buttonSize }}>
      <NoteSvg active={active} />
    </div>
  );
};

export default NoteIcon;
