import { styled } from "@mui/system";
import { tablet } from "../../../constants/breakpoints";

export const TimeWrapper = styled("div")(() => ({
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 16,
  ...tablet({
    fontSize: 20,
  }),
}));
export const TimerWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));
export const ImgWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));
export const CustomImg = styled("img")(({ theme, width, height }) => ({
  width: width,
  height: height,
}));