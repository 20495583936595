import React from "react";

type Props = {
  width?: number;
};

const Easy: React.FC<Props> = ({ width = 21 }) => {
  return (
    <svg
      width={width}
      height="20"
      viewBox="0 -1 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.744 17.2698C15.091 16.0595 15.2013 13.9951 13.991 12.6481C13.1744 11.7392 11.9648 11.3888 10.8708 11.6239L-9.53674e-06 14.2847L10.3662 17.885C11.4865 18.3156 12.7946 18.1229 13.744 17.2698Z"
        fill="#289AF4"
      />
      <path
        d="M19.7408 20.4061C19.1002 21.0551 18.0544 20.9043 17.5041 20.1772C16.9539 19.4501 17.1133 18.4235 17.7149 17.7383C18.4692 16.879 19.0385 15.8658 19.379 14.7614C19.8826 13.1277 19.8599 11.3771 19.3141 9.75703C18.7683 8.13697 17.7271 6.72948 16.3376 5.73358C14.9481 4.73767 13.2807 4.20376 11.5712 4.20733C9.86169 4.21091 8.19655 4.75178 6.81125 5.75349C5.42595 6.75519 4.39059 8.16702 3.85158 9.78934C3.60507 10.5313 3.46808 11.3001 3.44082 12.072L0.140907 12.7662C0.0928151 11.4112 0.285359 10.0503 0.717968 8.74821C1.4753 6.46879 2.93001 4.48512 4.8764 3.07769C6.8228 1.67027 9.16238 0.910318 11.5643 0.905298C13.9662 0.900279 16.309 1.65045 18.2612 3.04972C20.2135 4.449 21.6765 6.42658 22.4433 8.70281C23.2102 10.979 23.2421 13.4387 22.5345 15.7341C21.9899 17.501 21.0283 19.1016 19.7408 20.4061Z"
        fill="#289AF4"
      />
      <path
        d="M0.686541 15.8754C1.24335 17.6021 2.19987 19.1645 3.46943 20.4401C4.11265 21.0864 5.15789 20.9313 5.70509 20.2019C6.25229 19.4725 6.08858 18.4466 5.48416 17.7639C5.35726 17.6205 5.23554 17.4728 5.11919 17.3211L0.686541 15.8754Z"
        fill="#289AF4"
      />
    </svg>
  );
};

export default Easy;
