import React from "react";

type Props = {
  width?: number;
};

const Medium: React.FC<Props> = ({ width = 21 }) => {
  return (
    <svg
      width={width}
      height="20"
      viewBox="0 -1 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8715 16.6137C14.3883 18.359 12.5894 19.3777 10.8442 18.8945C9.66666 18.5685 8.8139 17.6419 8.53679 16.5577L6.07426 5.64025L13.9169 13.3158C14.8017 14.1267 15.2121 15.3836 14.8715 16.6137Z"
        fill="#289AF4"
      />
      <path
        d="M3.302 19.5009C3.94252 20.1498 4.9884 19.999 5.53864 19.2719C6.08888 18.5448 5.92946 17.5183 5.3279 16.833C4.57355 15.9737 4.00421 14.9605 3.66376 13.8561C3.16017 12.2224 3.18287 10.4718 3.72866 8.85176C4.05999 7.86828 4.57391 6.96313 5.23748 6.18085L4.28517 2.52105C2.58095 3.8887 1.29983 5.71855 0.599428 7.79754C-0.167419 10.0738 -0.199318 12.5335 0.508244 14.8288C1.0529 16.5957 2.01449 18.1964 3.302 19.5009Z"
        fill="#289AF4"
      />
      <path
        d="M4.95436 2.02297C6.87131 0.701805 9.14699 -0.0048471 11.4784 2.50251e-05C13.8804 0.00504441 16.22 0.764998 18.1664 2.17242C20.1127 3.57985 21.5675 5.56352 22.3248 7.84294C23.0821 10.1224 23.1037 12.5822 22.3866 14.8745C21.8346 16.6391 20.8663 18.2358 19.5733 19.5349C18.9301 20.1812 17.8849 20.026 17.3377 19.2966C16.7905 18.5672 16.9542 17.5413 17.5586 16.8586C18.3165 16.0024 18.8901 14.9916 19.2352 13.8887C19.7456 12.2571 19.7302 10.5064 19.1912 8.88407C18.6522 7.26175 17.6168 5.84991 16.2315 4.84821C14.8462 3.84651 13.1811 3.30563 11.4715 3.30206C10.0075 3.299 8.57436 3.69015 7.32021 4.4281L4.95436 2.02297Z"
        fill="#289AF4"
      />
    </svg>
  );
};

export default Medium;
