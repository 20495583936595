import React, { useEffect } from "react";

type Props = {
  display: string;
  width: number | string;
  height: number | string;
  dataAdSlot: string;
  isResponsive?: boolean;
};

const AdsBanner: React.FC<Props> = ({ display, width, height, dataAdSlot, isResponsive = true }) => {
  useEffect(() => {
    try {
      // @ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{
          display: display,
          width: width,
          height: height,
          zIndex: 5,
        }}
        data-ad-client="ca-pub-4652013909006807"
        data-ad-slot={dataAdSlot}
        data-ad-format="auto"
        data-full-width-responsive={isResponsive}
      />
    </>
  );
};

export default AdsBanner;
