import { styled } from "@mui/material";
import COLORS from "../../../constants/colors";
import { desktop, tablet, desktop_plus, phone_tablet } from "../../../constants/breakpoints";

export const CoreContent = styled("div")(({ theme }) => ({
  ...desktop({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: theme.spacing(6),
    ...desktop_plus({
      justifyContent: "space-between",
    }),
  }),
}));
export const GameContent = styled("div")(() => ({}));
export const BordWrapper = styled("div")(() => ({
  margin: "0 15px",
  ...tablet({
    margin: 0,
  }),
}));
export const UpperContent = styled("div")(({ theme, className }) => ({
  margin: theme.spacing(1, 0, 1, 0),
  display: "flex",
  flexDirection: className?.includes("row-reverse") ? "row-reverse" : "row",
  justifyContent: "space-between",
  alignItems: "center",
  ...tablet({
    margin: theme.spacing(2.5, 0, 2.5, 0),
  }),
  ...desktop({
    margin: theme.spacing(2.5, 0, 2.5, 0),
  }),
}));

export const DifficultyWrapper = styled("div")(({ theme }) => ({
  marginLeft: 8,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const DifficultyLabel = styled("span")(({ theme }) => ({
  color: COLORS.GRAY_FOR_TEXT,
  fontWeight: 500,
  fontSize: 18,
}));

export const LayoutWrapper = styled("div")(() => ({
  ...tablet({
    display: "flex",
    justifyContent: "space-between",
  }),
}));
export const GameWrapper = styled("div")(() => ({
  paddingLeft: 100,
  width: "90%",
  height: "calc(100vw*0.58)",
  margin: "32px 0 0 0",
  ...phone_tablet({
    paddingLeft: 0,
  }),
  ...tablet({
    width: 688,
    height: 443,
    paddingLeft: 0,
  }),
  ...desktop({
    width: 688,
    height: 443,
  }),
  ...desktop_plus({
    width: 852,
    height: 572,
    margin: "121px 0",
  }),
}));
