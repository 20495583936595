import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import dynamic from "next/dynamic";
import Router, { useRouter } from "next/router";

import { TABLET_MEDIA_QUERY } from "../../../constants/breakpoints";
import { GameProps as Props } from "../../../types/popup";
import { AnalyticsHelper } from "../../../utils/analyticEvents";
import useTranslation, { useRoutes } from "../../../helpers/translationTexts/useTranslation";
import { useSudokuContext } from "../context/SudokuContext";
import { generateSudoku } from "../solver/UniqueSudoku";
import { GameCompletedEvents } from "../../../constants/analyticEvents";

import Container from "../Container";
import NoGame from "./NoGame";
import SidePanel from "../../SidePanel";
import TimerSection from "../TimerSection";
import Controls from "./Controls";
import Board from "./Board";
import { isDailySudokuGameCheck } from "../../../utils/checkPageByPath";

import {
  calculateEveryNumberCount,
  nextSelectedNumber,
  isSudokuSolved,
  isErrorInCell,
  getCandidates,
  solveSudoku,
} from "../sudokuEngine";

import {
  CoreContent,
  DifficultyLabel,
  DifficultyWrapper,
  GameContent,
  LayoutWrapper,
  UpperContent,
  BordWrapper,
} from "./styles";
import SyncManager from "../../../api/SyncManager";
import annualAwardsManager from "../../../api/AnnualAwardsManager";
import { useSession } from "../../../context/SessionProvider";
import calculateBestTime from "../utils/calculeteBestTime";
import { currentDateAsString } from "../../../utils/dateHelper";
import { UserAnnualAward } from "../../../api/models/userAnnualAward";
import calculateLevelsLeft from "../utils/calculateLevelsLeft";
import { LEVELS } from "../../../constants/levels";
import pointsManager from "../../../api/PointsManager";
import { ControlHintsModalWarning } from "@/components/modal";
import GameWinModal from "../GameWinModal";

const DynamicSelect = dynamic(() => import("./SelectDifficulty"), {
  ssr: false,
});

const DynamicSelectDifficulty = DynamicSelect as React.FC;
const MainContent: React.FC<Props> = ({ dailyChallengeDate, sudokuDailyChallenge }) => {
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);
  const pathName = useRouter().asPath;
  const isDailySudoku = isDailySudokuGameCheck(pathName);
  const routerDailySudoku = useRoutes().DailySudoku;

  const isDailySudokuGame = isDailySudokuGameCheck(pathName);
  const { setAnnualAwardsCount, setUserPoints, userData, setShockModeData, setDailyProgress } = useSession();

  const [isOpenMonthlyAward, setIsOpenMonthlyAward] = useState(false);
  const [timeGame, setTimeGame] = useState<number>(0);
  const [isOpenControlHintsModalWarning, setIsOpenControlHintsModalWarning] = useState<boolean>(false);
  const [completedGames, setCompletedGames] = useState<UserAnnualAward>();
  const [isUsedNotes, setIsUsedNotes] = useState<boolean>(false);
  const [hintsCount, setHintsCount] = useState<number>(0);

  const dateQuery = (useRouter().query.date as string) || "";
  const yearGame: string = dateQuery.split("-")[0];
  const monthGame: string = dateQuery.split("-")[1];
  const dayGame: string = dateQuery.split("-")[2];
  const levelTranslation = useTranslation("level");

  const {
    gameHistory,
    setGameHistory,
    numberSelected,
    setNumberSelected,
    gameArray,
    setEraseEnabled,
    setHintEnabled,
    setGameArray,
    difficulty,
    isEraseEnabled,
    isHintEnabled,
    isNotesEnabled,
    setNotesEnabled,
    initArray,
    setInitArray,
    setEveryNumberCount,
    setGameCandidates,
    won,
    setWon,
    setIsPause,
  } = useSudokuContext();

  useEffect(() => {
    createNewGame();
  }, [difficulty, sudokuDailyChallenge]);

  useEffect(() => {
    updateCandidatesNumbers(gameArray);
  }, [isNotesEnabled]);

  useEffect(() => {
    const sendPassedGame = async () => {
      if (isDailySudoku) {
        const { date } = currentDateAsString();
        await SyncManager.sendOrStorePassedDailyLevel(
          initArray,
          gameArray,
          dailyChallengeDate!,
          timeGame,
          isUsedNotes,
          hintsCount,
          LEVELS.EASY.toLocaleUpperCase(),
          date
        );

        const savedCompletedGames = annualAwardsManager.loadMonth(yearGame, monthGame);
        setCompletedGames(savedCompletedGames);

        const awardsCount: number = SyncManager.getAnnualAwardsCount(yearGame);
        setAnnualAwardsCount(awardsCount);

        const points: number = pointsManager.load();
        setUserPoints(points);

        const dailyProgress: string = await SyncManager.getDailyProgress();
        setDailyProgress(dailyProgress);

        if (userData) {
          const shockModeData = await SyncManager.getShockModeData();
          setShockModeData(shockModeData);
        }
      } else {
        const { date } = currentDateAsString();
        const points = await SyncManager.sendOrStoreUserMonthStats({
          sudoku: initArray,
          solvedSudoku: gameArray,
          level: difficulty.toLowerCase(),
          wastedTimeSec: timeGame,
          notes: isUsedNotes,
          hints: hintsCount,
          date,
        });

        setUserPoints(points);

        if (userData) {
          const shockModeData = await SyncManager.getShockModeData();
          setShockModeData(shockModeData);
        }
      }
    };

    if (won && timeGame) {
      sendPassedGame();
    }
  }, [timeGame]);

  const onGameWinModalClosed = () => {
    if (isDailySudoku && SyncManager.isLastDailyChallenge(yearGame, monthGame)) {
      setIsOpenMonthlyAward(true);
    } else {
      if (isDailySudoku) {
        Router.push(routerDailySudoku);
      }
    }

    createNewGame();
    setHintsCount(0);
    setIsUsedNotes(false);
  };

  const notValidDailySudoku = () => {
    return isDailySudoku && (typeof sudokuDailyChallenge != "string" || sudokuDailyChallenge.length != 81);
  };

  const createNewGame = () => {
    // TODO improve validation
    const sudokuStr = typeof sudokuDailyChallenge === "string" ? sudokuDailyChallenge : "0";
    const newSudokuStr = isDailySudoku ? sudokuStr : generateSudoku(difficulty);
    updateBottomPanel(newSudokuStr);
    setInitArray(newSudokuStr);
    setGameArray(newSudokuStr);
    setNumberSelected("0");
    setGameHistory([]);
    setNotesEnabled(false);
    setWon(false);
  };

  const onNumberClicked = (number: string) => {
    setNumberSelected(number);
    setEraseEnabled(false);
    setHintEnabled(false);
  };

  const updateBottomPanel = (sudoku: string) => {
    const everyNumberCountLocal = calculateEveryNumberCount(sudoku);
    setEveryNumberCount(everyNumberCountLocal);

    // Update selected number on the bottom panel
    const nextSelectedNumberValue = nextSelectedNumber(parseInt(numberSelected), everyNumberCountLocal);

    // Don't select number, if Erase or Hint enabled
    if (!isEraseEnabled && !isHintEnabled) {
      setNumberSelected(nextSelectedNumberValue);
    }
  };

  function setCharAt(value: string, index: number, chr: string) {
    if (index > value.length - 1) return value;
    return value.substring(0, index) + chr + value.substring(index + 1);
  }

  /**
   * Fills the cell with the given 'value'
   * Used to Fill / Erase as required.
   */
  const onCellClicked = (index: number, value: string) => {
    // init value cannot be changed
    if (initArray[index] !== "0") {
      return;
    }

    // Direct copy results in interesting set of problems, investigate more!
    let tempArray: string = gameArray.slice();
    const tempHistory = gameHistory.slice();
    // Can't use tempArray here, due to Side effect below!!
    tempHistory.push(tempArray);
    setGameHistory(tempHistory);

    // clear incorrect number if need
    if (isEraseEnabled) {
      tempArray = setCharAt(tempArray, index, "0");
    } else if (tempArray[index] == "0" && isHintEnabled) {
      const solvedSudoku: string | boolean = solveSudoku(tempArray);
      if (typeof solvedSudoku === "string") {
        tempArray = setCharAt(tempArray, index, solvedSudoku[index]);
      }
    } else if (tempArray[index] == "0") {
      tempArray = setCharAt(tempArray, index, value);
    } else if (tempArray[index] == value || isErrorInCell(tempArray, index)) {
      tempArray = setCharAt(tempArray, index, "0");
    }

    setGameArray(tempArray);
    updateBottomPanel(tempArray);
    updateCandidatesNumbers(tempArray);

    if (isSudokuSolved(tempArray)) {
      setWon(true);
      const completeGameAction = isDailySudoku
        ? GameCompletedEvents.dailyGameCompleted
        : GameCompletedEvents.gameCompleted;

      AnalyticsHelper({
        action: completeGameAction,
        value: difficulty,
      });
    }
    if (isHintEnabled) {
      setHintsCount((prev) => prev + 1);
    }
  };

  const updateCandidatesNumbers = (sudoku: string) => {
    if (isNotesEnabled) {
      const candidates: number[][] = getCandidates(sudoku);
      setGameCandidates(candidates);
    }
  };

  const onUndoClicked = () => {
    if (gameHistory.length) {
      const tempHistory = gameHistory.slice();
      const tempArray = tempHistory.pop();
      setGameHistory(tempHistory);
      if (tempArray) {
        setGameArray(tempArray);
        updateBottomPanel(tempArray);
        updateCandidatesNumbers(tempArray);
      }
    }
  };

  const bestTime = calculateBestTime(dayGame, monthGame, yearGame, timeGame);
  const levelsLeft = calculateLevelsLeft(yearGame, monthGame, completedGames?.bestTime || []);
  return (
    <>
      <Container>
        <CoreContent>
          <>
            {notValidDailySudoku() ? (
              <NoGame />
            ) : (
              <GameContent>
                <UpperContent className={isDailySudoku ? "row-reverse" : "row"}>
                  {!isDailySudoku && (
                    <DifficultyWrapper>
                      {isTablet && <DifficultyLabel>{levelTranslation}</DifficultyLabel>}
                      <DynamicSelectDifficulty />
                    </DifficultyWrapper>
                  )}
                  <BordWrapper>
                    <TimerSection setTimeGame={setTimeGame}></TimerSection>
                  </BordWrapper>
                </UpperContent>
                <LayoutWrapper>
                  <BordWrapper>
                    <Board
                      onNumberClicked={(number) => onNumberClicked(number.toString())}
                      onCellClicked={(indexOfArray: number) => onCellClicked(indexOfArray, numberSelected)}
                    />
                  </BordWrapper>
                  <Controls
                    setIsUsedNotes={setIsUsedNotes}
                    isDailySudoku={isDailySudoku}
                    onUndoClicked={onUndoClicked}
                    hasHistory={gameHistory.length > 0}
                    onNewGameClicked={() => {
                      setIsPause(false);
                      createNewGame();
                    }}
                    showControlHintsModalWarning={() => setIsOpenControlHintsModalWarning(true)}
                  />
                </LayoutWrapper>
              </GameContent>
            )}
          </>
          {isTablet && <SidePanel />}
        </CoreContent>
      </Container>
      {!isTablet && <SidePanel />}

      <GameWinModal
        timeGame={timeGame}
        isModalOpen={won}
        onGameWinModalClosed={onGameWinModalClosed}
        progress={completedGames?.progress || 0}
        levelsLeft={levelsLeft}
        month={monthGame}
        notes={isUsedNotes}
        hints={hintsCount}
        difficulty={difficulty}
        isDailySudokuGame={isDailySudokuGame}
        isOpenMonthlyAward={isOpenMonthlyAward}
      />

      {isOpenControlHintsModalWarning && (
        <ControlHintsModalWarning
          isOpen={isOpenControlHintsModalWarning}
          onCloseClicked={() => setIsOpenControlHintsModalWarning(false)}
        />
      )}
    </>
  );
};

export default MainContent;
