import { Grid, styled } from "@mui/material";
import { phone_tablet, desktop, tablet } from "../../../../constants/breakpoints";
import COLORS from "../../../../constants/colors";

export const StyledCell = styled(Grid)(({ className }) => ({
  userSelect: "none",
  padding: 0,
  position: "relative",
  width: "calc(100% / 9)",
  borderTop: className?.includes("top")
    ? `1px solid ${COLORS.GAME_GRID_ROWS_2}`
    : `0.5px solid ${COLORS.GAME_GRID_ROWS}`,
  borderRight: className?.includes("right")
    ? `1px solid ${COLORS.GAME_GRID_ROWS_2}`
    : `0.5px solid ${COLORS.GAME_GRID_ROWS}`,
  borderBottom: className?.includes("bottom")
    ? `1px solid ${COLORS.GAME_GRID_ROWS_2}`
    : `0.5px solid ${COLORS.GAME_GRID_ROWS}`,
  borderLeft: className?.includes("left")
    ? `1px solid ${COLORS.GAME_GRID_ROWS_2}`
    : `0.5px solid ${COLORS.GAME_GRID_ROWS}`,

  "&::after": {
    content: "''",
    display: "block",
    paddingBottom: "100%",
  },
}));

export const StyledBoard = styled(Grid)(({ theme }) => ({
  outline: "none !important",
  position: "relative",
  background: COLORS.WHITE,
  border: `1px solid ${COLORS.GAME_GRID_ROWS_2}`,
  overflow: "hidden",
  borderRadius: theme.spacing(1.5),
  boxShadow: `inset 0 0 1px 1px ${COLORS.GAME_GRID_ROWS_2}`,
  ...tablet({
    width: 423,
    height: 423,
  }),
  ...desktop({
    width: 520,
    height: 520,
    marginRight: theme.spacing(4),
  }),
}));
export const ImageWrapper = styled("div")(() => ({
  position: "absolute",
  zIndex: 8,
  top: "54%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  ...phone_tablet({
    top: "52%",
    left: "50%",
  }),
  ...tablet({
    top: "54%",
    left: "50%",
  }),
}));
export const StyledImage = styled("img")(() => ({
  width: 130,
  height: 130,
  cursor: "pointer",
  ...phone_tablet({
    width: 160,
    height: 160,
  }),
  ...tablet({
    width: 180,
    height: 180,
  }),
}));
