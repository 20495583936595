import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { EraseIcon, HintsIcon, NoteIcon, UndoIcon } from "../../images";
import NumbersPad from "../NumbersPad";
import {
  ControlOption,
  ControlsContainer,
  ControlsWrapper,
  ControlTitle,
  ModeBox,
  NewGameButton,
  PlaceReserve,
} from "./styles";
import useTranslation from "../../../../helpers/translationTexts/useTranslation";
import { useSudokuContext } from "../../context/SudokuContext";
import { AnalyticsHelper } from "../../../../utils/analyticEvents";
import { GameStartEvents } from "../../../../constants/analyticEvents";
import { MOBILE_MEDIA_QUERY } from "../../../../constants/breakpoints";
import { LEVELS } from "@/constants/levels";

type ControlsProps = {
  hasHistory: boolean;
  onUndoClicked: () => void;
  onNewGameClicked: () => void;
  isDailySudoku: boolean;
  showControlHintsModalWarning: () => void;
  setIsUsedNotes: Dispatch<SetStateAction<boolean>>;
};

const Controls = ({
  hasHistory,
  onUndoClicked,
  onNewGameClicked,
  isDailySudoku,
  showControlHintsModalWarning,
  setIsUsedNotes,
}: ControlsProps) => {
  const {
    isEraseEnabled,
    setEraseEnabled,
    isNotesEnabled,
    setNotesEnabled,
    isHintEnabled,
    setHintEnabled,
    setNumberSelected,
    isPause,
    setIsPause,
    setStartGame,
    difficulty,
    won,
  } = useSudokuContext();
  const [isFirstRun, setIsFirstRun] = useState(false);
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

  const newGameText = useTranslation("newGame");

  useEffect(() => {
    setNotesEnabled(false);
  }, [isPause, setNotesEnabled]);

  useEffect(() => {
    if (isFirstRun && !won) {
      AnalyticsHelper({
        action: GameStartEvents[difficulty as LEVELS],
      });
    }
    if (won) {
      setIsFirstRun(false);
    }
  }, [difficulty, isFirstRun, won]);

  const onEraseClicked = () => {
    setEraseEnabled(!isEraseEnabled);
    setHintEnabled(false);
    setNumberSelected("0");
  };

  const onNotesClicked = () => {
    setNotesEnabled(!isNotesEnabled);
    setIsUsedNotes(true);
  };

  const onHintClicked = () => {
    if (isDailySudoku) {
      showControlHintsModalWarning();
    } else {
      setEraseEnabled(false);
      setHintEnabled(!isHintEnabled);
      setNumberSelected("0");
    }
  };

  const onNumberClicked = (number: string) => {
    setNumberSelected(number);
    setIsPause(false);
    setEraseEnabled(false);
    setHintEnabled(false);
    setStartGame(true);
    setIsFirstRun(true);
  };

  const NewButtonZone = () => {
    return !isDailySudoku ? (
      <NewGameButton onClick={() => onNewGameClicked()}>{newGameText}</NewGameButton>
    ) : (
      <PlaceReserve />
    );
  };

  return (
    <ControlsContainer>
      <ControlsWrapper>
        <ControlOption onClick={() => (!isPause ? onUndoClicked() : "")}>
          <UndoIcon active={hasHistory} />
          <ControlTitle>{useTranslation("undo")}</ControlTitle>
        </ControlOption>
        <ControlOption onClick={() => (!isPause ? onEraseClicked() : "")}>
          <EraseIcon active={isEraseEnabled} />
          <ControlTitle>{useTranslation("erase")}</ControlTitle>
          <ModeBox className={isEraseEnabled ? "" : "active"}>{isEraseEnabled ? "ON" : "OFF"}</ModeBox>
        </ControlOption>
        <ControlOption onClick={() => (!isPause ? onNotesClicked() : "")}>
          <NoteIcon active={isNotesEnabled} />
          <ControlTitle>{useTranslation("notes")}</ControlTitle>
          <ModeBox className={isNotesEnabled ? "" : "active"}>{isNotesEnabled ? "ON" : "OFF"}</ModeBox>
        </ControlOption>
        <ControlOption onClick={() => !isPause && onHintClicked()}>
          <HintsIcon active={isHintEnabled} />
          <ControlTitle>{useTranslation("hints")}</ControlTitle>
          <ModeBox className={isHintEnabled ? "" : "active"}>{isHintEnabled ? "ON" : "OFF"}</ModeBox>
        </ControlOption>
      </ControlsWrapper>
      <NumbersPad onNumberClicked={(number) => onNumberClicked(number.toString())} />
      {!isMobile ? <NewButtonZone /> : null}
    </ControlsContainer>
  );
};

export default Controls;
